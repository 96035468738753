import { useCallback } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import { Breadcrumbs, Loader } from 'components'
import { UserFormData } from 'domains/user/types'
import { UserForm } from 'domains/user/components/UserForm/UserForm'
import { usePutUserInfo } from 'domains/user/hooks/services/users/usePutUserInfo'
import { useGetUserInfo } from 'domains/user/hooks/services/users/useGetUserInfo'

import { useToast, useUserInfo } from 'shared/hooks'
import ImageDriver from 'services/image'

import styles from './UpdateUser.module.scss'
import { paths } from 'routes'

const UpdateUser = () => {
  const navigate = useNavigate()
  const { state } = useLocation()
  const { userId = '' } = useParams()

  const { addToast } = useToast()
  const { fetchUserInfo } = useUserInfo()

  const { data: userInfo, isLoading } = useGetUserInfo(userId, !!userId)
  const { mutate: mutateUpdateUserInfo, isPending } = usePutUserInfo(userId)

  const onSubmit = useCallback(
    (payload: UserFormData) => {
      mutateUpdateUserInfo(payload, {
        onSuccess: async (data) => {
          if (payload.avatarFile) {
            try {
              await ImageDriver.persist(payload.avatarFile as File, {
                principalOwner: {
                  id: data.id,
                  name: 'internalProfile',
                },
                owners: [
                  {
                    id: data.id,
                    name: 'internalProfile',
                  },
                ],
              })
            } catch (error) {
              console.debug('Erro ao criar imagem')
            }
          }
          fetchUserInfo()
          addToast({ message: 'Usuário editado com sucesso.' })
          navigate(-1)
        },
        onError: () => {
          addToast({
            message: 'Erro ao editar usuário. Tente novamente.',
            type: 'alert',
          })
        },
      })
    },
    [mutateUpdateUserInfo, addToast, navigate],
  )

  if (!userInfo || isLoading || isPending) {
    return <Loader isVisible />
  }

  return (
    <div className={styles.container}>
      <Breadcrumbs
        items={[
          { title: 'Home', href: '/' },
          { title: 'Configurações', href: '' },
          {
            title: 'Gestão de usuários',
            href: paths.configuration.user.list + '?page=1',
          },
          { title: userInfo?.name || '', href: '' },
        ]}
      />
      <div className={styles.headerSeparator} />

      <UserForm
        user={userInfo}
        onSubmit={onSubmit}
        onGoBack={() => navigate(-1)}
        readOnly={!!state?.readOnly}
      />
    </div>
  )
}

export default UpdateUser
